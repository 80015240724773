body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  box-sizing: border-box;
}
*{
  box-sizing: border-box !important;
}
Link {
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.contact:hover .activeButtonDash {
  visibility: visible;
}
#send{
  background-color: #57b33e;
  padding: 8px 30px;
  font-weight: 500;
  color: white;
  font-size: 18px;
}
#send:hover{
  background-color: #f09433;
}
/* active button */
.activeButtonDash {
  font-weight: 900;
  color: green;
  margin: 0;
  padding: 0;
  visibility: hidden;
}
/* home */
.contactUsBtnOnHome:hover {
  background-color: black !important;
  color: white !important;
}
.contactForm{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
/* introToINDIVM */
.introToINDIVM {
  background-color: #f7f7f7;
  margin: 0 !important  ;
}
.solar {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ececec;
  font-family: Poppins;
  position: absolute;
  font-size: 150px;
  color: #f7f7f7;
  transform: translate(40px, 30px);
  z-index: 1;
}
.solarPanelImg-HomeIntro {
  width: 41vw !important;
}

.investingIsClean {
  padding: 100px;
  z-index: 100;
  margin: 0 !important;
  text-align: justify;
  position: relative;
}
.investingIsClean .h1 {
  font-size: 57px;
}
.learnMoreBtn {
  border: 0;
  background-color: #57b33e;
  padding: 10px 30px;
  font-weight: bold;
  color: white;
}
.learnMoreBtn:hover {
  background-color: #ff7029 !important;
  color: white;
}
.contactWhatsappBtn:hover {
  color: #ff7029;
}
.email:hover a{
  color: #f09433 !important;
}
.phone:hover{
  color: #f09433 !important;
}
/* about */
.about {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ececec;
  font-family: Poppins;
  position: absolute;
  font-size: 150px;
}
.aboutContainer {
  padding: 0;
  z-index: 1;
  position: relative;
  padding: 80px 10px;
  width: 100% !important;
}
.aboutUsImg1 {
  width: 480px;
}
.fa-circle-down:hover {
  color: #57b33e;
}
.aboutDownlaodBtn button {
  background-color: white;

  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
}
.aboutDownlaodBtn i {
  align-items: center;
}
.megawattsOfCapacity {
  position: absolute;
  transform: translate(35px, -145px);
  width: 200px;
}
/* why us */
.whyUsData {
  padding: 1.5em !important;
}
.whyChooseUs {
  min-height: 100vh;
}
.whyUsData:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.enhancedServices {
  transform: translate(0px, 0px);
}
.man-worker-firld-by-solar {
  transform: translate(0px, 30px);
  width: 285px;
}
.group-people-working-out-business-plan-office {
  transform: translate(0px, -20px);
  width: 285px;
}
.maintenanceServices {
  transform: translate(0px, 20px);
}
/* works projects */

.recentProjects {
  height: 100vh !important;
  background-color: #f8f8f8;
}
.recentProjectsHeading {
  padding: 60px 0px;
}
.works {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ececec;
  font-family: Poppins;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 150px;
  width: 100%;
  z-index: 1;
  position: absolute;
  text-align: center;
}
.projectGallery {
  margin-top: 120px !important;
}
.projectGallery a:hover {
  background-color: #57b33e;
  cursor: pointer;
}
.projectGallery img {
  filter: grayscale(0);
  transition: 0.5s ease-in-out;
}
.projectGallery a:hover img {
  filter: grayscale(1);
  transition: 0.5s ease-in-out;
}
/* clients */

.clients {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ececec;
  font-family: Poppins;
  position: absolute;
  font-size: 150px;
  transform: translate(130px, 30px);
}
.clientBody {
  position: relative;
  z-index: inherit;
  padding: 150px 200px;
}

.someFacts {
  max-width: 570px;

  background-color: #1f242c;
}
.clientInnerBody {
  max-height: 570px;
}
.turbine_bg {
  z-index: 1;
  display: none;
}

.clientReviewing {
  max-width: 50%;
}
.someFactsPages {
  position: absolute;
  z-index: 6 !important;

  transform: translate(437px, 96px);
}
.turbineBackground {
  position: absolute;
  right: 0;
  z-index: 1;
}
.say {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ececec;
  font-family: Poppins;
  position: absolute;
  transform: translate(780px, 650px);
  font-size: 150px;
}
.someBasics{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ececec;
  font-family: Poppins;
  position: absolute;
  font-size: 150px;
}

/* .turbineSketch{
  width: 400px;
  position: relative;
  transform: translate(0px,500px);
  left: 0;
  z-index: 1;
} */

/* weAreHere */
.weAreHere {
  margin: 0 10%;
  height: 25vh;
  z-index: 1;
}
.parallax {
  /* The image used */
  background-image: url("../src/img/world\ map.png");

  /* Full height */
  height: 100%;
  margin-bottom: 50px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/*  parallax-home*/

/* footer */
.footer {
  min-height: 90vh;
  background-color: #20242b;
  margin-top: 100px;
  padding: 150px 200px 150px 200px;
  color: white !important;
}

/* socialIcons */
.socialIcons-fb:hover {
  background-color: #3b5998;
}
.socialIcons-whatsapp:hover {
  color: #fff;
  background: linear-gradient(#25d366, #25d366) 14% 84%/16% 16% no-repeat,
    radial-gradient(#25d366 58%, transparent 0);
}
.socialIcons-call:hover {
  background-color: #3b5998;
}
.socialIcons-instagram:hover {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
/* whatsapp btn */
.whatsAppBtn {
  position: fixed;
  width: 41px !important;
  height: 41px !important;
  border: 0;
  right: 5%;
  bottom: 20%;
  z-index: 99;
  cursor: pointer;
  border-radius: 50%;
}

/* products */
.productsIntro {
  width: 100%;
  background-image: url(./img/products\ bg.png);
  background-repeat: no-repeat;
  height: 50vh;
}
/* products */
.galleryIntro {
  width: 100%;
  background-image: url(./img/gallery_bg.png);
  background-repeat: no-repeat;
  height: 50vh;
}
.productBody {
  margin: 100px 130px;
}
/* .productSmallImg img {
  height: 90px;
  margin: 10px 0;
  display: block;
  cursor: pointer;
  opacity: .6;
} */
/* .productSmallImg img:hover{
  opacity: 1;
} */
.productImg img {
  height: 500px;
  transition: transform 0.5s ease;
}

.productImg {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px 80px;
  overflow: hidden;
}
.productImg:hover img {
  transform: scale(1.5);
  cursor: pointer;
}
.getInquiry:hover {
  background-color: #f09433 !important;
}

/* contactUs */
.contactUsIntro {
  width: 100%;
  background-image: url(./img/construction_bg.png);
  background-repeat: no-repeat;
  height: 50vh;
}
.contactUsIntro Link:hover {
  text-decoration: underline !important;
}
.contactUsBody {
  position: relative;
  z-index: 10;
  display: flex;
  padding-top: 50px;
  flex-direction: row !important;
}
.contacts-lg {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ececec;
  font-family: Poppins;
  position: absolute;
  width: 60%;
  transform: translate(80px, 10px);
  z-index: 1;
  font-size: 180px;
}
/* scroll btn */
#scrollToTop {
  position: fixed;
  width: 55px !important;
  height: 55px !important;
  border: 0;
  right: 4%;
  bottom: 6%;
  z-index: 99;
  cursor: pointer;
  background-color: #ff8400;
  color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
#scrollToTop:hover {
  background-color: #333333;
}
.productSolarPanel {
  width: 100%;
  background-image: url(./img/solarPanel_bg.png);
  background-repeat: no-repeat;
  height: 50vh;
}
/* about us */
.aboutUsIntro {
  width: 100vw;
  background-image: url(./img/construction_bg.png);
  background-repeat: no-repeat;
  height: 50vh;
}
.aboutUsIntro Link:hover {
  text-decoration: underline !important;
}
/* solar panel */
.solarPanelBody {
  padding: 100px;
}
.solarPanelCard {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  max-width: 270px;
  margin: 10px;
}
.solarPanelBody li:hover a {
  color: #ff8400 !important;
  cursor: pointer;
}
.solarPanelCardImage {
  max-width: 270px;
  max-height: 270px;
}
.solarPanelCardImage img {
  max-height: 200px;
}
.sideBarForSolarPanel {
  display: block;
}
/* gallery css */
.galleryRow {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.galleryColumn {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.galleryColumn img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}
.contactFrom{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 100px;
}
.relatedProduct{
  margin: 30px 170px;
}
.relatedProductImg img{
  width: 80%;
  
}
.relatedProduct h2{
  text-align: center;
}

/* responsive layout - for tablet */
@media screen and (max-width: 1270px) {
  .works {
    font-size: 100px;
  }
  .solar {
    font-size: 120px;
    transform: translate(10px,0px);
  }

  .investingIsClean {
    padding: 50px;
    margin: 0 !important;
  }
  .about {
    font-size: 120px;
  }
  .megawattsOfCapacity {
    transform: translate(10px, -145px);
  }
  .enhancedServices {
    transform: translate(0px, 0px);
    width: 225px;
  }
  .weAreHere {
    margin: 0 5%;
  }
  .man-worker-firld-by-solar {
    transform: translate(0px, 30px);
    width: 225px;
  }
  .group-people-working-out-business-plan-office {
    transform: translate(0px, -20px);
    width: 225px;
  }
  .maintenanceServices {
    transform: translate(0px, 20px);
    width: 225px;
  }
  .aboutContainer {
    padding: 30px;
  }
  .clients {
    font-size: 80px;
    transform: translate(120px, 5px);
  }
  .say {
    font-size: 80px;
    transform: translate(700px, 500px);
    display: none;
  }
  .turbine_bg {
    width: 500px;
    right: 0;
  }
  .someFacts {
    width: 50%;
    padding: 15px !important;
  }
  .clientBody {
    padding: 70px;
  }
  .footer {
    padding: 80px;
  }
  .aboutBody {
    margin: 60px;
  }
  .productImg{
    padding: 0;
  }
}
.aboutBody {
  margin: 80px;
}
@media screen and (max-width: 1000px) {
  .investingIsClean .h1 {
    font-size: 28px;
  }
  .relatedProduct{
    margin: 30px 100px !important;
  }
  .aboutUsImg1 {
    width: 100%;
  }
  .whyChooseUs {
    margin: 40px;
  }
  
  .aboutContainer {
    padding: 40px 0px;
  }
  .about {
    font-size: 80px;
  }
  .enhancedServices {
    width: 180px;
    transform: translate(0px, 0px);
  }
  .man-worker-firld-by-solar {
    transform: translate(0px, 30px);
    width: 180px;
  }
  .group-people-working-out-business-plan-office {
    transform: translate(0px, -20px);
    width: 180px;
  }
  .maintenanceServices {
    transform: translate(0px, 20px);
    width: 180px;
  }
  
 
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .weAreHere {
    margin: 0;
  }
  .galleryColumn {
    flex: 50%;
    max-width: 50%;
  }
  .solarPanelCard {
    max-width: 100%;
  }
  .solarPanelCardImage {
    max-width: 100%;
    max-height: 270px;
  } 
  .solarPanelCardImage img {
    max-height: 250px;
  }
  
  .sideBarForSolarPanel {
    display: block;
  }
  .investingIsClean .h1 {
    font-size: 38px;
  }
  .solarPanelImg-HomeIntro {
    display: none;
  }
  .introToINDIVM{
    background-color: rgb(247 247 247) !important;
    width: 100% !important;
  }
  .relatedProduct{
    margin: 20px 80px !important;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .galleryColumn {
    flex: 100%;
    max-width: 100%;
  }
  .solarPanelCard {
    max-width: 100%;
    margin: 20px 10px;
  }
  .solarPanelCardImage {
    max-width: 100%;
    max-height: 270px;
  }
  .solarPanelCardImage img {
    max-height: 250px;
  }
  .sideBarForSolarPanel {
    display: none;
  }
  .solarPanelBody {
    padding: 30px;
  }
}

@media all and (max-width: 652px) {
  /* clients */
  .clients {
    position: unset;
    transform: translate(0px, 0px);
    font-size: 66px !important;
    display: none;
  }
  .clientReviewing {
    display: none !important;
  }
  .clientBody {
    padding: 20px 0px;
  }
  .clientReviewing img {
    position: unset;
    display: none;
    z-index: 5;
    transform: translate(0px, 0px);
  }
  .someFacts {
    width: 100%;
    height: unset;
    position: unset;
    z-index: 6;
    transform: translate(0px, 0px);
    width: 100vw;
    padding: 0;
  }

  .say {
    position: relative;
    transform: translate(0px, 0px);
    display: none;
  }
  /* solar */
  
  
  .solarPanelImg-HomeIntro {
    width: 100vw !important;
    display: none;
  }
  
  .investingIsClean .h1 {
    font-size: 26px;
    
  }
  .investingIsClean{
    padding: 33px;
  }
  
  
  .learnMoreBtn {
    padding: 10px;
  }
  /* about */
  .about {
    position: absolute !important;
    font-size: 66px;
  }
  .aboutBody {
    height: 130vh;
    margin: 0px 20px ;
  }
  .aboutContainer {
    padding: 20px 0px;
    width: 100%;
    margin: 0 !important;
  }
  .aboutUsImg1 {
    transform: translate(right) !important;
  }
  .megawattsOfCapacity {
    transform: translate(15px, -145px);
  }
  .turbineBackground img {
    display: none;
  }
  .clientReviewing {
    width: 100vw;
  }

  .whyUsData {
    padding: 15px 0 !important;
  }
  .whyChooseUs {
    min-height: 150vh !important;
  }
  .enhancedServices {
    transform: translate(0px, 0px);
    width: 100%;
  }
  .man-worker-firld-by-solar {
    transform: translate(0px, 0px);
    display: none;
  }
  .group-people-working-out-business-plan-office {
    width: 100%;
    display: none;

    transform: translate(0px, 0px);
  }
  .maintenanceServices {
    width: 100%;

    transform: translate(0px, 0px);
  }
  .works {
    font-size: 66px;
  }
  .navbar {
    width: 100%;
  }
  .projectGallery img {
    width: 100%;
  }
  .projectGallery {
    position: unset;
  }
  /*  */
  .recentProjects {
    margin-top: 0px !important;
    height: unset !important;
    background-color: #f8f8f8;
  }
  .recentProjectsHeading {
    padding: 20px 0px;
  }
  .weAreHere {
    height: 38vh;
    z-index: 1;
    margin: 0;
  }
  .contactUsBtnOnHome {
    position: absolute;
  }
  .parallax {
    /* The image used */
    background-image: url("../src/img/world\ map.png");
    padding: 25px;
    /* Full height */
    height: inherit !important;
    margin-bottom: unset;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .whatsAppBtn {
    display: none;
  }
  /* footer */
  .footer {
    margin-top: 20px;
    padding: 20px 15px 20px 15px;
  }
  .contacts-lg {
    position: absolute;
    z-index: 1;
    font-size: 66px;
    transform: translate(20px, 0px);
  }
  .someBasics{
    position: absolute;
    z-index: 1;
    font-size: 66px;
    transform: translate(0px, 0px);
  }
  .contactUsBody {
    position: relative;
    padding-top: 20px;
  }
  .about {
    position: unset;
    font-size: 66px !important;
  }
  /* .product */
  .productBody {
    margin: 0px 0px;
  }
  .productImg {
    box-shadow: none;
    padding: 40px 0px;
    overflow: hidden;
  }
  .solar{
    font-size: 50px;
  }
}
